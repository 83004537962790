<template>
    <div>
        <Navbar />
        <div class="root_terms">
            <div class="heading_terms">
                Prohibited Items
            </div>
            <div class="text_terms">


                We do not accept or ship any items that can be considered offensive, prohibited, hazardous or dangerous
                items, such as fire extinguishers, chemicals, plants, seeds, animal parts, weapons, ammunition,
                parts/accessory of weapons or counterfeit item or other items that a mail carrier would not accept

                <div class="heading__">
                    Regulations
                </div>
                Please note that all items are shipped via air and so we follow much more stringent restrictions than for
                carriers that ship via ground or sea.
                <div>
                </div>
                The following are examples of the type of merchandise or use of
                forwarding shipping services prohibited by the Forward Parcel. The list is not exhaustive, and the company
                reserves the right to investigate and take appropriate action, including returning merchandise, suspending
                or terminating account, and/or reporting matter to law enforcement authorities, should the Company
                reasonably believe it to be required by applicable law, or if it reasonably suspects fraudulent or otherwise
                illegal activity is afoot. The Customer is responsible for ensuring that all items sent to our warehouse
                satisfy all laws and regulations of India

                <ul>
                    <li>All items subjected to Export Control by India</li>
                    <li>All items subjected to Import restrictions by transit or destination countries</li>
                    <li>Hazardous or combustible materials (as defined in IATA Regulations)</li>
                    <li>Flammable liquids/solids, Oxidizing substances</li>
                    <li>Toxic/infectious substances including Rat Poisons, Pesticides, etc.</li>
                    <li>Radioctive material</li>
                    <li>Corrosive like cleaning fluids</li>
                    <li>Electronics that require Export licenses like Radars, Sonars, Telecom equipment, etc</li>
                    <li>Live or dead animals</li>
                    <li>Hunting (Animal) trophies, animal parts such as ivory, skins, and sharks’ fin, animal remains, or
                    </li>
                    <li>Animal-by-Products</li>
                    <li>Human and animal remains or ashes</li>
                    <li>Bullion (of any precious metal)</li>
                    <li>Asbestos, dry ice, magnetized material</li>
                    <li>Currency, Cash, Travelers checks,</li>
                    <li>Loose precious and semi-precious stones</li>
                    <li>All illegal drugs, controlled substances, narcotics, and drug paraphernalia</li>
                    <li>Products with batteries</li>
                    <li>Agarbattis/Incense sticks (Limited quantity - 1kg of agarbattis/incense per shipment allowed)</li>
                    <li>Unidentified or improperly/insufficiently marked powders, liquids, fluids or substances</li>
                    <li>Gambling items like lottery tickets, slot machines, etc.</li>
                    <li>Goods containing compressed air like shock absorbers, air bags,</li>
                    <li>Counterfeit goods or fake items</li>
                    <li>Alcoholic beverages</li>
                    <li>Antiques like statues, sculptures and artwork</li>
                    <li>All plant seeds</li>
                    <li>Drugs: prescription and non-prescription</li>
                    <li>Ivory</li>
                    <li>Pornography</li>
                    <li>Soil samples</li>
                    <li>Lighters containing fuel</li>
                    <li>Law enforcement or defence equipment including handcuffs, batons, shields, helmets, body armor,
                        smoke</li>
                    <li>signals etc</li>
                    <li>All explosives, firearms, weapons including any gun parts or accessories, etc.</li>
                    <li>Mace, Stun guns, etc</li>
                    <li>Imitation Jewellery without appraiser certificate</li>
                    <li>Milk, Meat and Perishable food products</li>
                    <li>Food products without FSSAI certification to Australia and New Zealand</li>
                </ul>



            </div>
        </div>
        <FooterYic />
    </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import Navbar from "../navbar/Navbar.vue";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
} from "bootstrap-vue";
export default {
    components: {
        BButton,
        BModal,
        BAlert,
        Navbar,
        BRow,
        BImg,
        BCol,
        BLink,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        FooterYic,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            arr: [
                {
                    heading: 'Introduction',
                    content: [
                        'www.YourIndianCart.com offers parcel forwarding, online Store along with package consolidation services from India to USA. When you sign up for an account, you will receive a virtual Indian Address and Suite Number where we can receive your packages. When YourIndianCart receives a package addressed to you, we will add them into your Suite and we will notify you. This can be viewed on the dashboard under the Items in warehouse tab. Once all items are received you can request consolidation after which we will send the package on to your address in your home country',
                        'YourIndianCart provides free membership which includes the following.',
                        [
                            'FREE Indian Address',
                            'FREE Package receiving',
                            'FREE 3 Photo of each item received',
                            'FREE item verification for damage',
                            'FREE Storage up to 30 days',
                            'FREE Package Return processing of your purchased items domestically within India contingent upon seller’s return policy.',
                            'FREE Consolidation of multiple packages',
                            'FREE efficient packing to ensure no valuable space goes unused.',
                            'FREE E-mail updates',
                            'FREE real time shipment tracking',
                            'FREE My Dashboard utility to track items and package status',
                            'FREE Pickup from anywhere in South India',
                            'FREE Customs declaration paperwork',
                            'Shop items from YIC store ',
                            'Pay for all services using your overseas credit/debit card, Indian credit/debit card and Net Banking  ',
                        ],
                        'YourIndianCart service charges for one shipping order - INR 500.',
                        'Note - YourIndianCart service charges include all the above items including shipping boxes, packaging materials plus all the items above.'
                    ]
                },
                {
                    heading: '2.0 User Agreement:',
                    content: [
                        'This User Agreement (“Agreement “) is a legal agreement between you (referred to as ‘User’, ‘Recipient’, ‘You’, ‘Member’ And ‘Your’) and www.YourIndianCart.com (referred to as ‘YourIndianCart’, ‘We’, ‘Us’, or ‘Our’) is owned and operated by PRI10 E-Solutions Private Limited, a Sole Proprietary registered in India under Company Identification Number (CIN) U74999TN2022PTC155253.',
                        'All services provided by YourIndianCart are described in www.YourIndianCart.com and are collectively referred to in this Agreement as “Services.” As a condition of your use of this Site and Services, you represent and warrant to YourIndianCart that you will not use this Site or the Services for any purpose that is unlawful, immoral or prohibited by these terms, conditions and notices.',
                        'This Agreement supplements the terms and conditions of the Services described in the Site. By accessing the Site or opening a new account with YourIndianCart.com, you agree to be bound by and hereby become a party to all the terms of this Agreement. If you do not agree with the terms and conditions in this Agreement, you may not use YourIndianCart.com or its Services.',
                        'From time to time and as needed or required by law, we will amend, modify, or change the terms of this Agreement by making those changes directly to the Agreement on this Site. These changes take effect when posted to the site. By continuing to access or use YourIndianCart.com after any such amendment, you agree to be bound by the terms of the amended Agreement.',
                        'Please read the entire terms and conditions of use carefully before you use YourIndianCart.com. If you do not agree to all the terms and conditions, you may not use the site. By using the site, you are deemed to have read and accepted these terms and conditions and are at least 18 years of age',
                        'You agree not to use the Site or the Services to:',
                        [
                            'Harm or threaten to harm YourIndianCart or users in any way, including by using information you find here to compete against YourIndianCart;',
                            'Impersonate any person or entity (including YourIndianCart or its agent), or falsely state or otherwise misrepresent your affiliation with a person or entity;',
                            'Disseminate or transmit any content that infringes or violates any party’s copyright, trademark, trade secret, patent or other rights;',
                            'Violate any law (whether local, state, national, or international), whether or not intentionally;',
                            'Forge Internet protocol headers or otherwise manipulate identifying information in order to disguise your identity;',
                            'Provide fraudulent, inaccurate, or incomplete information to YourIndianCart or any shipping service or government agency;',
                            'Interfere with or disrupt the Services, the Site, or servers or networks connected to the Site, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site;',
                            'Collect or store personal data about other users;',
                            'Engage in any activities that would violate the personal privacy rights of others, including but not limited to, collecting and distributing information about Internet users without their permission, except as permitted by applicable law;',
                            'or post or cause to be uploaded to YourIndianCart’s computer servers or network any material that contains software viruses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.',
                        ],
                    ]
                },
                {
                    heading: '3.0 Our Site',
                },
                {
                    heading: '3.1 No Automated Queries:',
                    content: [
                        'You may not send automated queries of any sort to the Site without express permission in advance from YourIndianCart. Note that “sending automated queries” includes, among other things:',
                        [
                            'using any software that sends queries to the Site to determine how a website or web page “ranks” for various queries;',
                            '“meta-searching”; and',
                            'performing “offline” searches on any YourIndianCart website.',
                        ]
                    ]
                },
                {
                    heading: '3.2 No Copies',
                    content: [
                        'You may not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sub license, grant a security interest in or otherwise transfer any right in the Services or the Site, or remove or obscure the copyright notice or other notices displayed on the Site. Except as provided above, you may not reproduce, print, cache, store or distribute content retrieved from the Site in any way, for any commercial use without the prior written permission of YourIndianCart.',
                    ]
                },
                {
                    heading: '3.3 Copyrights',
                    content: [
                        'All content available on YourIndianCart.com, including site design, text, graphics, interfaces and the selection of arrangements thereof is copyrighted by YourIndianCart, with all rights reserved, or is the property of YourIndianCart or third parties protected by intellectual property rights.',
                        'If you believe content on YourIndianCart.com infringes your copyright, you should send notice of copyright infringement to YourIndianCart.',
                        'The notice should be emailed to: support@YourIndianCart.com',
                        'Upon receipt of notice, YourIndianCart.com will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from YourIndianCart.com.',
                    ]
                },
                {
                    heading: '3.4 Registration',
                    content: [
                        'Before you use the Services, you must open a YourIndianCart Account by completing the YourIndianCart sign up form with true, accurate, current and complete information about yourself (“Registration Information”). You agree to maintain and update the Registration Information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or if YourIndianCart has reasonable grounds to suspect that information you have provided is untrue, inaccurate, not current or incomplete, YourIndianCart has the right to suspend or terminate your access and refuse you any and all use of the Site and the Services.',
                    ]
                },
                {
                    heading: '3.5 Fraud Prevention',
                    content: [
                        'YourIndianCart is continually working to prevent any type of fraudulent activity. We leverage third party tools to prevent payment fraud, various industry standards to protect your account information and may, at any time, update our methods and tools. We also require that all items you send to our facilities have been purchased by you and paid for in full. If we are notified that fraudulent activity has been detected in any fashion, we reserve the right to refuse shipment until such time the seller has verified the transaction or as we otherwise determine in our sole discretion. Please send any suspected fraud inquiries to support@YourIndianCart.com',
                    ]
                },
                {
                    heading: '3.6 Password',
                    content: [
                        'When your registration is complete, you may access your individual YourIndianCart Account and other secure areas of YourIndianCart.com using a password. You are responsible for safeguarding the password. You agree not to disclose your password to any third party. You agree to take sole responsibility for any activities or actions under your password, whether or not you have authorized such activities or actions. You will immediately notify YourIndianCart.com of any unauthorized use of your password.',
                    ]
                },
                {
                    heading: '3.7 Prohibited Items',
                    content: [
                        'YourIndianCart does not ship weapons (including items that assist in the functionality or manufacturing of firearms/ammunition or replicas of such items) or narcotics or other prohibited items. In addition, certain items may require special permissions or documentation or may be prohibited by your destination address or country. It is your responsibility to know these restrictions.',
                        'For more information on Prohibited Items please see Prohibited Items page under the services section on the home page.',
                        'YourIndianCart cooperates with law enforcement agencies to ensure that prohibited items are not shipped via YourIndianCart. This includes the routine inspection by law enforcement agencies of YourIndianCart’s facilities and its customers’ packages. By entering into this agreement, you accept and agree to routine inspections of your packages by law enforcement agencies. If your package is found to contain prohibited items, you agree that the contents of your package may be seized and confiscated by law enforcement or YourIndianCart. If prohibited items are found in your packages as determined by law enforcement or YourIndianCart, your account will be placed on hold, and any additional packages within your Locker may also be inspected and confiscated.'
                    ]
                },
                {
                    heading: '4.0 Our Services',
                },
                {
                    heading: '4.1 Addresses',
                    content: [
                        'Each package sent to YourIndianCart’s address must be addressed correctly. Your correct name and YourIndianCart Suite number must be written on each package. If a package arrives with an incorrect or incomplete address (for example missing Suite number or an unknown or misspelled name), YourIndianCart will try to determine whom it belongs to. If YourIndianCart is able to determine the owner of the package, it will be entered into the correct suite. If the owner cannot be determined the package will be disposed of at the sole discretion of YourIndianCart. Your Indian address can be found under the account settings section once you have signed up for an account.',
                    ]
                },
                {
                    heading: '4.3 Storage',
                    content: [
                        'YourIndianCart will store your packages in our warehouse for up to 30 days for without charge while we wait to receive all of the packages which has been ordered. However, if your package needs to be stored in our warehouse for more than the above period you may opt to extend the storage time for a fee or the parcel may be considered abandoned.',
                    ]
                },
                {
                    heading: '4.4 Abandoned Packages',
                    content: [
                        'YourIndianCart will dispose of any abandoned/unclaimed packages.',
                    ]
                },
                {
                    heading: '4.5 Package Refusal/Return to Sender',
                    content: [
                        'We are not able to refuse receipt of packages from carriers. Packages can be returned to vendors after they are processed into your account via Return Request which is free of charge.',
                    ]
                },
                {
                    heading: '4.6 Oversized Packages',
                    content: [
                        'Oversized packages are any package over the size or weight limits set forth in the by the specified carrier. Oversized packages are required to be divided, reduced, disposed of or returned to the sender if such packages are beyond the limits of the specified carrier. Maximum piece weight should not exceed 70kg and maximum dimensions should not exceed 274 cm in length',
                    ]
                },
                {
                    heading: '4.7 Photographs of Packages',
                    content: [
                        'Upon your request, YourIndianCart will open and photograph the contents of a package, allowing you to confirm the merchandise is correct and in good condition before requesting Return Service. Up to 3 photographs will be uploaded against each item receive. You should be able to see them under your dashboard.',
                    ]
                },
                {
                    heading: '4.8 Consolidation',
                    content: [
                        'YourIndianCart is not responsible for items missing from a consolidated package without proof that the missing item was received by us. All items received by YourIndianCart is photographed and loaded into your account. We will remove all extra packaging during consolidation which will reduce the amount of space occupied in the shipping box. This helps bring down the shipping costs.   ',
                    ]
                },
                {
                    heading: '4.9 Operations Timelines:',
                    content: [
                        'Please be advised of the operations timelines to complete each process:',
                        'Inbound – 24 to 48 hours from the time the parcel is delivered to our warehouse.',
                        'Consolidation – 24 to 48 hours from the time you request consolidation.',
                        'Dispatch – 24 to 48 hours from the time you pay the shipping charge.',
                        'Operations working hours: Mon-Fri 10AM-6PM. ',
                    ]
                },
                {
                    heading: '5.0 YourIndianCart (YIC) Store.',
                },
                {
                    heading: '5.1 Orders',
                },

                // {
                // 	heading: '',
                // 	content: [
                // 		'',
                // 	]
                // },

            ]
        };
    },
};

</script>
<style scoped>
.root_terms {
    color: #000;
    padding: 0 8rem;
    background-color: white;
}

.heading_terms {
    font-weight: 600;
    font-size: 43px;
    color: #414141;
    margin: 2rem 0;
    margin-bottom: 0rem;

}

.text_terms {
    display: flex;
    flex-direction: column;
    text-align: justify;
}



.heading__ {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.heading__>ul {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}


/*========================= Media Queries (Medium devices) =========================*/
@media screen and (max-width: 1000px) {
    .root_terms {
        padding: 0 5rem;
    }
}

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
    .root_terms {
        padding: 0 2rem;
    }
}
</style>